<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li>Statistiken</li>
            </ul>
            <div class="header-actions">
              <p><a :href="download_link" class="button button-orange button-small">PDF Download ›</a></p>
            </div>
          </header>

          <div class="admin-body">

            <div class="row row-gutter-20">
              <div class="col-8">
                <div class="form-wrap select-wrap">
                  <label for="selected_month">Monat auswählen</label>
                  <select v-model="selected_month" id="selected_month">
                    <option value="all">Ganzes Jahr</option>
                    <option v-for="month in available_months" v-bind:key="month.id" v-bind:value="month.id">{{ month.name }}</option>
                  </select>
                </div>
              </div>
              <div class="col-8">
                <div class="form-wrap select-wrap">
                  <label for="selected_year">Jahr auswählen</label>
                  <select v-model="selected_year" id="selected_year">
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                  </select>
                </div>
              </div>
              <div class="col-8">
                <div class="form-wrap select-wrap">
                  <label for="selected_year">Standort auswählen</label>
                  <select v-model="selected_location" id="selected_month">
                    <option value="all">Alle Standorte</option>
                    <option v-for="location in locations" v-bind:key="location.id" v-bind:value="location.id">{{ location.name }}</option>
                  </select>
                </div>
              </div>
            </div>

            <div v-if="loading">
              <div class="loading-wrap">
                <div class="loading-spinner"></div>
              </div>
            </div>
            <div v-else>

              <div v-if="stats.amount">
                <div class="well">
                  <h3 style="font-size:16px;font-weight:400;margin:0 0 10px 0">Gesamteinnahmen Brutto</h3>
                  <h4 style="font-size:30px;margin:0">{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(stats.amount.sum_gross) }}</h4>
                </div>
              </div>

              <div class="row row-gutter-20">
                <div class="col-8">
                  <div class="well">
                    <h3 style="font-size:16px;font-weight:400;margin:0 0 10px 0">via Stripe</h3>
                    <h4 style="font-size:24px;margin:0">{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(stats.distribution.stripe_amount) }}</h4>
                    <h5 style="font-size:14px;color:#A6A6A6;margin:10px 0 0 0;font-weight:400">{{ stats.distribution.stripe_count }} Reservierungen</h5>
                  </div>
                </div>
                <div class="col-8">
                  <div class="well">
                    <h3 style="font-size:16px;font-weight:400;margin:0 0 10px 0">via Barzahlung</h3>
                    <h4 style="font-size:24px;margin:0">{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(stats.distribution.cash_amount) }}</h4>
                    <h5 style="font-size:14px;color:#A6A6A6;margin:10px 0 0 0;font-weight:400">{{ stats.distribution.cash_count }} Reservierungen</h5>
                  </div>
                </div>
                <div class="col-8">
                  <div class="well">
                    <h3 style="font-size:16px;font-weight:400;margin:0 0 10px 0">via EC-Zahlung</h3>
                    <h4 style="font-size:24px;margin:0">{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(stats.distribution.ec_amount) }}</h4>
                    <h5 style="font-size:14px;color:#A6A6A6;margin:10px 0 0 0;font-weight:400">{{ stats.distribution.ec_count }} Reservierungen</h5>
                  </div>
                </div>
                <div class="col-8">
                  <div class="well">
                    <h3 style="font-size:16px;font-weight:400;margin:0 0 10px 0">via Überweisung</h3>
                    <h4 style="font-size:24px;margin:0">{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(stats.distribution.bank_transfer_amount) }}</h4>
                    <h5 style="font-size:14px;color:#A6A6A6;margin:10px 0 0 0;font-weight:400">{{ stats.distribution.bank_transfer_count }} Reservierungen</h5>
                  </div>
                </div>
                <div class="col-8">
                  <div class="well">
                    <h3 style="font-size:16px;font-weight:400;margin:0 0 10px 0">Nicht zugewiesen</h3>
                    <h4 style="font-size:24px;margin:0">{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(stats.distribution.other_amount) }}</h4>
                    <h5 style="font-size:14px;color:#A6A6A6;margin:10px 0 0 0;font-weight:400">{{ stats.distribution.other_count }} Reservierungen</h5>
                  </div>
                </div>
              </div>

              <div class="row row-gutter-20">
                <div class="col-12">
                  <div class="well">
                    <h3 style="font-size:16px;font-weight:400;margin:0 0 10px 0">Provision ({{ stats.commission.percent }}%)</h3>
                    <h4 style="font-size:30px;margin:0">{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(stats.commission.amount) }}</h4>
                  </div>
                </div>
                <div class="col-12">
                  <div class="well">
                    <h3 style="font-size:16px;font-weight:400;margin:0 0 10px 0">Provision ({{ stats.commission.percent_cash }}%) <strong>NUR BAR</strong></h3>
                    <h4 style="font-size:30px;margin:0">{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(stats.commission.amount_cash) }}</h4>
                  </div>
                </div>
              </div>

              <div class="well">
                <h3>Rechnungen</h3>

                <table class="table">
                  <thead>
                    <tr>
                      <th width="10%">Nummer</th>
                      <th width="10%">Datum</th>
                      <th width="35%">Name</th>
                      <th width="10%">Betrag</th>
                      <th width="20%">Details</th>
                      <th width="15%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="invoice in stats.invoices" v-bind:key="invoice.id">
                      <td>000{{ invoice.number }}</td>
                      <td>{{ invoice.date_formatted }}</td>
                      <td><span v-if="invoice.company">{{ invoice.company }}<br></span>{{ invoice.first_name }} {{ invoice.last_name }}</td>
                      <td>{{ parseFloat(invoice.sum_gross).toFixed(2) }} €</td>
                      <td>
                        <span v-if="invoice.reservation_id">Reservierung: {{ invoice.reservation_id }}</span>
                        <span v-if="invoice.damage_id">Schaden: {{ invoice.damage_id }}</span>
                      </td>
                      <td style="text-align:right"><router-link :to="'/invoices/'+invoice.uuid"><span class="material-icons">receipt</span></router-link></td>
                    </tr>
                  </tbody>
                </table>

              </div>

            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../components/admin_nav'
import moment from 'moment'

export default {
  name: 'admin_dashboard',
  components: {
    AdminNav
  },
  data () {
    return {
      locations: [],
      stats: "",
      available_months: [],
      selected_month: "all",
      selected_year: "2021",
      selected_location: "all",
      loading: true,
      download_link: ""
    }
  },
  methods: {
    get_data() {
      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/locations', { headers: { Authorization: this.$store.getters.getToken } })
      .then(response => {
        this.locations = response.data.locations;
      })
      this.download_link = process.env.VUE_APP_BASE_API+'/v1/admin/statistics/pdf?uuid=ac6f4d02-b436-44e5-b38e-18241232d696&key=f6a25fc3-39e3-45d8-8055-420b0743ddd1&selected_month='+this.selected_month+'&selected_year='+this.selected_year+'&selected_location='+this.selected_location
    },
    get_statistics() {
      this.loading = true;
      this.download_link = process.env.VUE_APP_BASE_API+'/v1/admin/statistics/pdf?uuid=ac6f4d02-b436-44e5-b38e-18241232d696&key=f6a25fc3-39e3-45d8-8055-420b0743ddd1&selected_month='+this.selected_month+'&selected_year='+this.selected_year+'&selected_location='+this.selected_location
      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/statistics?selected_month='+this.selected_month+'&selected_year='+this.selected_year+'&selected_location='+this.selected_location, { headers: { Authorization: this.$store.getters.getToken } })
      .then(response => {
        this.stats = response.data;
        this.loading = false;
      })
    },
  },
  mounted () {
    this.get_data();

    moment.updateLocale('en', {
      months : [
        "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"
      ]
    });

    var times = 12;
    for(var i=0; i < times; i++){
      this.available_months.push({id: moment(new Date('January 15, 2021 03:24:00').setMonth(i)).format("M"), name: moment(new Date('January 15, 2021 03:24:00').setMonth(i)).format("MMMM")})
    }

    this.get_statistics();
  },
  watch: {
    selected_month: function() {
      this.get_statistics();
    },
    selected_year: function() {
      this.get_statistics();
    },
    selected_location: function() {
      this.get_statistics();
    },
  }
}
</script>

<style lang="scss">


</style>
